import React from "react"
import Layout from "../components/common/layout"
import HeroSection from "../components/hero-section"
import { StaticImage } from "gatsby-plugin-image"
import {
  BGLinearGradient,
  SeparatorExtended,
} from "../components/common/styledComponentsHelper"
import {
  newsText1,
  newsText2,
  newsText3,
  newsText4,
  newsText5,
  newsText6,
  newsText7,
  newsText8,
  newsText9,
} from "../static/text/newsContent"
import TextCompactor from "../components/common/textCompactor"

export const Head = () => (
  <>
    <title>Presse | Fachhandwerk360</title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
  </>
)

const Presse = () => {
  return (
    <Layout>
      <HeroSection
        desktopImg="news_banner_desktop.png"
        mobileImg="news_banner_mobile.png"
        headlineText="Presse"
        responsiveHeadline="Presse"
        subheadlineText="Alles Wissenswerte rund um Fachhandwerk360"
        captionText=""
        buttons={false}
      />
      <BGLinearGradient>
        <SeparatorExtended />
        <div className="container align-items-stretch">
          <div className="row">
            <div className="col-xl-3 mb-4">
              <div className="card h-100 border-0">
                <StaticImage
                  src="../images/presse/geldschein-verbrennt-vollkosten-stunden.png"
                  className="card-img-top"
                  alt="Tipps zur Kosten und Preiskalkulation für Selbstständige"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Solo-Selbstständige: „60 Euro pro Stunde reichen nicht!“
                  </h5>
                  <br />
                  <p className="card-text">
                    <TextCompactor
                      fullText={newsText9}
                      textLengthToShow={110}
                    />
                  </p>
                  <a
                    href="https://www.handwerk.com/tipps-zur-kosten-und-preiskalkulation-fuer-selbststaendige"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    zum Artikel
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-4">
              <div className="card h-100 border-0">
                <StaticImage
                  src="../images/presse/impuls-mitgliedermagazin.png"
                  className="card-img-top"
                  alt="Impulse Mitgliedermagazin"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Bierdeckelzahlen, um Gewinne einzufahren
                  </h5>
                  <br />
                  <p className="card-text">
                    <TextCompactor
                      fullText={newsText8}
                      textLengthToShow={110}
                    />
                  </p>
                  <a
                    href="https://www.e-masters.de/aktuelles/impulse-mitgliedermagazin.html"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    zum Artikel
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-4">
              <div className="card h-100 border-0">
                <StaticImage
                  src="../images/presse/theodor_roehm.png"
                  className="card-img-top"
                  alt="Theodor Roehm"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Ich hatte lange Zeit Hemmungen, die Preise anzuheben
                  </h5>
                  <br />
                  <p className="card-text">
                    <TextCompactor
                      fullText={newsText7}
                      textLengthToShow={110}
                    />
                  </p>
                  <a
                    href="https://www.handwerk.com/ich-hatte-lange-zeit-hemmungen-die-preise-anzuheben?fbclid=IwAR395YHbM231rbHnD062wiLU-Vol9-QMqzd1ukeJvsmyryruTbOkmrMm5EY"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    zum Artikel
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-4">
              <div className="card h-100 border-0">
                <StaticImage
                  src="../images/presse/baugewerbe-magazin.png"
                  className="card-img-top"
                  alt="Fachhandwerk 360° mit Bierdeckel in der Hero Community"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Fachhandwerk 360° mit Bierdeckel in der Hero Community
                  </h5>
                  <br />
                  <p className="card-text">
                    <TextCompactor
                      fullText={newsText6}
                      textLengthToShow={110}
                    />
                  </p>
                  <a
                    href="https://www.baugewerbe-magazin.de/personen/fachhandwerk-360--mit-bierdeckel--in-der-hero-community.htm"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    zum Artikel
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-4">
              <div className="card h-100 border-0">
                <StaticImage
                  src="../images/presse/elektropraktiker.png"
                  className="card-img-top"
                  alt="Expertenwissen für Handwerker vom Fachhandwerk 360°"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Expertenwissen für Handwerker vom Fachhandwerk 360°
                  </h5>
                  <br />
                  <p className="card-text">
                    <TextCompactor
                      fullText={newsText5}
                      textLengthToShow={110}
                    />
                  </p>
                  <a
                    href="https://www.elektropraktiker.de/nachricht/expertenwissen-fuer-handwerker-vom-fachhandwerk-360/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    zum Artikel
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-4">
              <div className="card h-100 border-0">
                <StaticImage
                  src="../images/presse/sicht-sonnenschutz.png"
                  className="card-img-top"
                  alt="Fachhandwerk 360° und Hero Software kooperieren"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Fachhandwerk 360° und Hero Software kooperieren
                  </h5>
                  <br />
                  <p className="card-text">
                    <TextCompactor
                      fullText={newsText4}
                      textLengthToShow={110}
                    />
                  </p>
                  <a
                    href="https://www.sicht-sonnenschutz.com/fachhandwerk-360-und-hero-software-kooperieren-268609/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    zum Artikel
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-4">
              <div className="card h-100 border-0">
                <StaticImage
                  src="../images/presse/e-masters-seminare.png"
                  className="card-img-top"
                  alt="Aktueller, flexibler und digital"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Aktueller, flexibler und digital
                  </h5>
                  <br />
                  <p className="card-text">
                    <TextCompactor
                      fullText={newsText3}
                      textLengthToShow={110}
                    />
                  </p>
                  <a
                    href="https://www.e-masters.de/ws/impulse/index.html#30"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    zum Artikel
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-4">
              <div className="card h-100 border-0">
                <StaticImage
                  src="../images/presse/welche-auftraege-lohnen-sich-fuer-ihren-betrieb.png"
                  className="card-img-top"
                  alt="Welche Auftraege lohnen sich fuer ihren Betrieb"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Welche Aufträge lohnen sich fuer ihren Betrieb
                  </h5>
                  <p className="card-text">
                    <TextCompactor
                      fullText={newsText2}
                      textLengthToShow={110}
                    />
                  </p>
                  <a
                    href="https://www.handwerk.com/welche-auftraege-lohnen-sich-fuer-ihren-betrieb"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    zum Artikel
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 mb-4">
              <div className="card h-100 border-0">
                <StaticImage
                  src="../images/presse/preisuntergrenze-dieser-meister-sortiert-angebote-aus.png"
                  className="card-img-top"
                  alt="Preisuntergrenze dieser Meister sortiert Angebote aus"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Preisuntergrenze dieser Meister sortiert Angebote aus
                  </h5>
                  <p className="card-text">
                    <TextCompactor
                      fullText={newsText1}
                      textLengthToShow={110}
                    />
                  </p>
                  <a
                    href="https://www.handwerk.com/preisuntergrenze-dieser-meister-sortiert-angebote-aus"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    zum Artikel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SeparatorExtended />
      </BGLinearGradient>
    </Layout>
  )
}

export default Presse
